var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RouteName } from "@/app/routing/routing";
import { UserRoles } from "@/app/utils/DesktopLayoutUtils";
import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { STORE } from "@/store";
import { action, computed, observable } from "mobx";
import { DEFAULT_NAVIGATION_ITEMS, SUPERVISOR_DEFAULT_NAVIGATION_ITEMS } from "./init/default-configs";
export class ModuleDesktopLayout {
    constructor() {
        this._navs = DEFAULT_NAVIGATION_ITEMS;
        this.isDesktopLayoutLoaded = true; // Setting true until we can actually get the layout. Until then it blocks my view (yanaha)
        this.updateLayoutMainConfigs = (config) => {
            this.layoutMainConfigs = config;
        };
        //adding dynamic home nav with other default navs
        this.addDynamicHomeNav = (homeNav) => {
            this.removeDynamicNavIcon(homeNav);
            DEFAULT_NAVIGATION_ITEMS.forEach((item) => this.removeDynamicNavIcon(item));
            this.insertNavItem(homeNav, 0);
            SUPERVISOR_DEFAULT_NAVIGATION_ITEMS.forEach((nav) => {
                if (nav.navigateTo === RouteName.TASK) {
                    if ((STORE.agent.userSelectedRole === UserRoles.SUPERVISOR && STORE.agent.getMCMEnabled) ||
                        STORE.agent.userSelectedRole !== UserRoles.SUPERVISOR) {
                        STORE.layout.addDynamicNavIcon(nav);
                    }
                }
                else {
                    STORE.layout.addDynamicNavIcon(nav);
                }
            });
        };
        //adding default home nav if dynamic homenav not present
        this.addDefaultHomeNav = (defaultHomeNav) => {
            this.removeDynamicNavIcon(defaultHomeNav);
            DEFAULT_NAVIGATION_ITEMS.forEach((item) => this.removeDynamicNavIcon(item));
            this.insertNavItem(defaultHomeNav, 0);
            DEFAULT_NAVIGATION_ITEMS.forEach((nav) => {
                if (nav.navigateTo !== RouteName.TASK) {
                    STORE.layout.addDynamicNavIcon(nav);
                }
            });
        };
    }
    get navigation() {
        return JSON.parse(JSON.stringify(this._navs));
    }
    insertNavItem(item, idx) {
        let label = item.label;
        const upd = (v) => (label = v);
        const { value } = UTILS_DYNAMIC.storeDataProvider(label, upd);
        item.label = value;
        this._navs = [...this._navs.slice(0, idx), item, ...this._navs.slice(idx)];
    }
    updateApsDisplay() {
        const apsItem = {
            label: "app:navigation.analytics",
            icon: "analysis",
            iconActive: "analysis-active",
            iconType: "momentum",
            navigateTo: "aps",
            align: "top",
            isResponsive: false
        };
        this.removeDynamicNavIcon(apsItem);
        this._navs.splice(1, 0, apsItem);
        this._navs = [...this._navs];
    }
    addDynamicNavIcon(item) {
        // Removing if particular nav item existed at other position
        // and adding as per layout order
        this.removeDynamicNavIcon(item);
        if (item.align === "top") {
            const navsRev = [...this._navs].reverse();
            const _item = navsRev.find((i) => i.align === "top"); // Last TOP
            const idx = _item ? this._navs.indexOf(_item) + 1 : 0;
            this.insertNavItem(item, idx);
        }
        if (item.align === "bottom") {
            const _item = this._navs.find((i) => i.align === "bottom"); // First BOTTOM
            const idx = _item ? this._navs.indexOf(_item) : this._navs.length;
            this.insertNavItem(item, idx);
        }
    }
    addDefaultNavIcon(item) {
        this.removeDynamicNavIcon(item);
        this.insertNavItem(item, 0);
    }
    removeDynamicNavIcon(item) {
        this._navs = this._navs.filter((_item) => _item.navigateTo !== item.navigateTo);
    }
    removeDynamicNavigationTo(navigateTo) {
        this._navs = this._navs.filter((_item) => _item.navigateTo !== navigateTo);
    }
    updateDynamicNavIcon(oldItem, newItem) {
        const idx = this._navs.findIndex((_item) => _item.navigateTo === oldItem.navigateTo);
        if (idx !== -1) {
            this.removeDynamicNavIcon(oldItem);
            this.insertNavItem(newItem, idx);
        }
        else {
            this.addDynamicNavIcon(newItem);
        }
    }
    updateDesktopLayoutLoaded(status) {
        this.isDesktopLayoutLoaded = status;
    }
}
__decorate([
    observable
], ModuleDesktopLayout.prototype, "_navs", void 0);
__decorate([
    observable
], ModuleDesktopLayout.prototype, "isDesktopLayoutLoaded", void 0);
__decorate([
    observable
], ModuleDesktopLayout.prototype, "layoutMainConfigs", void 0);
__decorate([
    computed
], ModuleDesktopLayout.prototype, "navigation", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "insertNavItem", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "updateApsDisplay", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "addDynamicNavIcon", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "addDefaultNavIcon", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "removeDynamicNavIcon", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "removeDynamicNavigationTo", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "updateDynamicNavIcon", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "updateDesktopLayoutLoaded", null);
__decorate([
    action
], ModuleDesktopLayout.prototype, "updateLayoutMainConfigs", void 0);
__decorate([
    action
], ModuleDesktopLayout.prototype, "addDynamicHomeNav", void 0);
__decorate([
    action
], ModuleDesktopLayout.prototype, "addDefaultHomeNav", void 0);
export const layout = new ModuleDesktopLayout();
