var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createLogger } from "@/sdk";
/**
 * We are using local storage to keep the list of interaction for which auto wrap up timer us running .
 * session storage is used to keep this list since we lose track of which particular auto wrap is cancelled on reload.
 * Whenever auot wrap is cancelled for an interaction we use to remove it from the array.
 * In addition we are storing the list of interaction for which auto wrap up was canceled as a seperate key.
 * This is done in order to check if the interaction is not present in the first list because autowrapup was cancelled and not due to any other reason.
 */
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import * as workerTimers from "@uuip/unified-ui-platform-sdk";
import { DateTime } from "luxon";
import { action, computed, observable, toJS } from "mobx";
const logger = createLogger("[store-interaction-control] [InteractionControl]");
export default class InteractionControl {
    constructor() {
        this.autoWrapUp = [];
        this.autoWrapUpTimer = {};
        this.timerId = 0;
        this.getDefaultWrapUpReason = (resonList) => {
            return resonList === null || resonList === void 0 ? void 0 : resonList.find(({ isDefault }) => isDefault === true);
        };
        this.clearTimers = () => {
            workerTimers.clearTimeout(this.timerId);
        };
        this.submitHandler = (interactionIdValue, wrapUpContent) => {
            var _a;
            if (((_a = STORE.agentContact.taskSelected) === null || _a === void 0 ? void 0 : _a.interactionId) === interactionIdValue ||
                this.autoWrapUp.indexOf(interactionIdValue) === -1) {
                return;
            }
            const defaultReason = this.getDefaultWrapUpReason(wrapUpContent.wrapUpReasonList);
            const wrappUpReq = SERVICE.aqm.contact.wrapup({
                interactionId: interactionIdValue,
                data: {
                    auxCodeId: (defaultReason === null || defaultReason === void 0 ? void 0 : defaultReason.id.toString()) || "",
                    wrapUpReason: (defaultReason === null || defaultReason === void 0 ? void 0 : defaultReason.name) || "",
                    isAutoWrapup: "true"
                }
            });
            wrappUpReq
                .then(() => {
                STORE.session.interactionControl.removeInteraction(interactionIdValue);
                logger.info("event=WrapUp | Wrappedup Event: interactionId=", interactionIdValue);
                delete this.autoWrapUpTimer[interactionIdValue];
            })
                .catch(() => {
                delete this.autoWrapUpTimer[interactionIdValue];
            });
        };
        this.deleteAutoWrapTimer = (interactionId) => {
            clearTimeout(this.autoWrapUpTimer[interactionId]);
        };
        this.addAutoWrapUpTimers = (interactionId, wrapUpTimeStamp, autoWrapUpData) => {
            var _a;
            const wrapUpInterval = ((_a = autoWrapUpData === null || autoWrapUpData === void 0 ? void 0 : autoWrapUpData.wrapUpProps) === null || _a === void 0 ? void 0 : _a.autoWrapupInterval) || 60000; // if autowarpInterval is not available then setting default value as 60 seconds;
            const setTimeoutRunner = DateTime.fromMillis(wrapUpTimeStamp).plus({
                seconds: wrapUpInterval / 1000 + 1
            });
            this.autoWrapUpTimer[interactionId] = toJS(workerTimers.setTimeout(() => {
                this.submitHandler.call(this, interactionId, autoWrapUpData.wrapUpProps);
            }, setTimeoutRunner.diff(DateTime.local().plus({ milliseconds: 1000 })).milliseconds));
            this.timerId = this.autoWrapUpTimer[interactionId];
        };
    }
    get getListOfAutoWrapUp() {
        return toJS(this.autoWrapUp);
    }
    get autoWrapUpTimersRunning() {
        return toJS(this.autoWrapUpTimer);
    }
    addInteraction(interactionId) {
        this.autoWrapUp.push(interactionId);
        sessionStorage.setItem("autoWrapUpInteraction", JSON.parse(JSON.stringify(toJS(this.autoWrapUp))));
    }
    /**
     * this method is used to check the interactionid in the session storage set using canceled autoWrapup.
     */
    getIndexOfCanceledAutoWrapupInteration(interactionId) {
        var _a;
        return (_a = sessionStorage.getItem("canceledAutoWrapUpInteraction")) === null || _a === void 0 ? void 0 : _a.split(",").indexOf(interactionId);
    }
    removeInteraction(interactionId) {
        if (this.autoWrapUp.indexOf(interactionId) > -1) {
            this.autoWrapUp.splice(this.autoWrapUp.indexOf(interactionId), 1);
            sessionStorage.setItem("autoWrapUpInteraction", JSON.parse(JSON.stringify(toJS(this.autoWrapUp))));
        }
    }
    getIndex(data) {
        var _a;
        return (_a = sessionStorage.getItem("autoWrapUpInteraction")) === null || _a === void 0 ? void 0 : _a.split(",").indexOf(data.interactionId);
    }
    /**
     * this method is used to set the interactionId in the session when agent-contact event comes,
     * incase this was not stored due to wrapUp event not received by the application because of disconnect.
     */
    setAutoWrapupState(data, agentId, wrapUpData) {
        var _a;
        const isInteractionAlreadyInList = this.getIndex(data);
        const isCanceledAutoWrapup = this.getIndexOfCanceledAutoWrapupInteration(data.interactionId);
        if (((_a = wrapUpData === null || wrapUpData === void 0 ? void 0 : wrapUpData.wrapUpProps) === null || _a === void 0 ? void 0 : _a.autoWrapup) &&
            (isInteractionAlreadyInList === -1 || isInteractionAlreadyInList === undefined) &&
            (isCanceledAutoWrapup === -1 || isCanceledAutoWrapup === undefined) &&
            data.interaction.participants[agentId]["isWrapUp"]) {
            this.addInteraction(data.interactionId);
        }
    }
    checkForWrapUp(data, agentId) {
        if (STORE.agent.wrapUpData.wrapUpProps.autoWrapup &&
            this.getIndex(data) !== -1 &&
            data.interaction.participants[agentId]["wrapUpTimestamp"]) {
            this.addAutoWrapUpTimers(data.interactionId, data.interaction.participants[agentId]["wrapUpTimestamp"], toJS(STORE.agent.wrapUpData));
            this.addInteraction(data.interactionId);
        }
    }
}
__decorate([
    observable
], InteractionControl.prototype, "autoWrapUp", void 0);
__decorate([
    observable
], InteractionControl.prototype, "autoWrapUpTimer", void 0);
__decorate([
    observable
], InteractionControl.prototype, "timerId", void 0);
__decorate([
    action
], InteractionControl.prototype, "clearTimers", void 0);
__decorate([
    action
], InteractionControl.prototype, "submitHandler", void 0);
__decorate([
    computed
], InteractionControl.prototype, "getListOfAutoWrapUp", null);
__decorate([
    computed
], InteractionControl.prototype, "autoWrapUpTimersRunning", null);
__decorate([
    action
], InteractionControl.prototype, "deleteAutoWrapTimer", void 0);
__decorate([
    action
], InteractionControl.prototype, "addAutoWrapUpTimers", void 0);
__decorate([
    action
], InteractionControl.prototype, "addInteraction", null);
__decorate([
    action
], InteractionControl.prototype, "getIndexOfCanceledAutoWrapupInteration", null);
__decorate([
    action
], InteractionControl.prototype, "removeInteraction", null);
__decorate([
    action
], InteractionControl.prototype, "setAutoWrapupState", null);
__decorate([
    action
], InteractionControl.prototype, "checkForWrapUp", null);
export const interactionControl = new InteractionControl();
